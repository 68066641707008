import React, { createContext, useState, useEffect } from 'react';
import { ApolloProvider } from '@apollo/client';
import { UserProvider } from '@/hooks/UserContext';
import client from '@/config/apollo';

export const ThemeContext = createContext(null);

export const ThemeContextProvider = ({ children }) => {
    const [theme, setTheme] = useState('light');
    const [mounted, setMounted] = useState(false);

    console.log(theme)

    useEffect(() => {
        const localTheme = window.localStorage.getItem('theme');
        if (localTheme) {
            setTheme(localTheme);
        } else {
            setTheme('light');
        }
        setMounted(true);
    }, []);

    const toggleTheme = () => {
        setTheme((prevTheme) => (prevTheme === 'light' ? 'dark' : 'light'));
    };
    

    
    return (
        <ThemeContext.Provider value={{ theme, toggleTheme }}>
            {mounted && (
                <div className={theme === 'light' ? 'light-theme' : 'dark-theme'}>
                    {children}
                </div>
            )}
        </ThemeContext.Provider>
    );
};
