import { ApolloClient, InMemoryCache, createHttpLink } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { onError } from '@apollo/client/link/error';
import { createUploadLink } from 'apollo-upload-client';
import fetch from 'cross-fetch';
import jwtDecode from 'jwt-decode';

const httpLink = createHttpLink({
  //uri: 'http://localhost:4000/graphql',
  uri: 'https://backendmagazine-production.up.railway.app/',
  fetch,
});

const uploadLink = createUploadLink({
  //uri: 'http://localhost:4000/graphql',
  uri : 'https://backendmagazine-production.up.railway.app/',
  headers: {
    'Content-Type': 'application/json',
  },
  fetch,
});

function isValidToken(token) {
  try {
    const { exp } = jwtDecode(token);
    return Date.now() < exp * 1000;
  } catch (error) {
    return false;
  }
}

const authLink = setContext((_, { headers }) => {
  // Obtener el token del almacenamiento local del navegador
  const token = typeof window !== "undefined" ? localStorage.getItem('token') : null;
  
  if (token && !isValidToken(token)) {
    localStorage.removeItem('token');
    client.resetStore();
  }
  
  return {
    headers: {
      ...headers,
      authorization: token ? `Bearer ${token}` : '',
    },
  };
});

// Manejo de errores de GraphQL
const errorLink = onError(({ graphQLErrors, networkError }) => {
  if (graphQLErrors) {
    graphQLErrors.forEach(({ message, locations, path }) => {
      console.error(
        `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`,
      );
    });
  }
  if (networkError) console.error(`[Network error]: ${networkError}`);
});

const client = new ApolloClient({
  link: authLink.concat(uploadLink).concat(errorLink).concat(httpLink),
  cache: new InMemoryCache(),
});

export default client;
