import '@/styles/globals.css';
import '../styles/theme.css';
import React from 'react';
import { ApolloProvider } from '@apollo/client';
import { UserProvider } from '@/hooks/UserContext';
import client from '@/config/apollo';
import { ThemeContextProvider }from '../hooks/ThemeContext';

export default function App({ Component, pageProps }) {
  return (
    <ApolloProvider client={client}>
      <UserProvider>
    <ThemeContextProvider>
          <Component {...pageProps} />
    </ThemeContextProvider> 
      </UserProvider>
    </ApolloProvider>
  );
}
