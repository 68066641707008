import { createContext, useState, useEffect } from "react";
import jwt from 'jsonwebtoken';

const UserContext = createContext(null);

export const UserProvider = ({ children }) => {
  const [user, setUser] = useState(null);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const fetchUser = async () => {
      if (typeof window !== "undefined") {
        try {
          const token = localStorage.getItem("token");
          if (token) {
            const decodedUser = jwt.decode(token);
            setUser(decodedUser);
          }
        } catch (error) {
          console.error("Failed to retrieve user from local storage.", error);
          localStorage.removeItem("token");
          setUser(null);
        } finally {
          setIsLoading(false);
        }
      }
    };

    fetchUser();
  }, []);

  return (
    <UserContext.Provider value={{ user, setUser, isLoading }}>
      {children}
    </UserContext.Provider>
  );
};

export default UserContext;